<template>
  <div :class="$style.container">
    <div :class="$style.info">
      <ui-avatar
        size="custom"
        :class="$style.avatar"
        :src="accountStore.account.avatar"
        :border="accountStore.account.styles.avatarBorderColor"
        :sticker="accountStore.account.styles.sticker"
      />
      <div :class="$style.about">
        <ui-username
          :class="$style.username"
          :color="accountStore.account.styles.usernameColor"
          >{{ accountStore.account.username }}
        </ui-username>
        <div :class="$style.role">
          <component v-if="roleIcon" :is="roleIcon" :class="$style.icon" />
          <span :class="$style.t">{{ roleName }}</span>
        </div>
      </div>
    </div>
    <div :class="$style.stats">
      <div :class="$style.item">
        <span :class="$style.key">{{ t('date') }}</span>
        <ui-time
          :class="$style.value"
          :value="clan.clanMember.created_at"
          to-ms
        />
      </div>
      <div :class="[$style.item, $style.right]">
        <span :class="$style.key">{{ t('scores') }}</span>
        <span :class="$style.value">{{
          number(clan.clanMember.points_period)
        }}</span>
      </div>
    </div>
  </div>
  <ui-button
    size="large"
    theme="black"
    fill
    uppercase
    @click="onLeave"
    :loading="pending"
  >
    {{ isRemove ? t('delete') : t('leave') }}
  </ui-button>
</template>

<i18n lang="yaml">
ru:
  delete: Распустить клан
  date: Дата вступления
  scores: Очков
  leave: Покинуть клан
  dialog-title: Ты уверен?
  yes: Да
en:
  delete: Dissolve the clan
  date: Date of entry
  scores: Scores
  leave: Leave clan
  dialog-title: Are you sure?
  yes: Yes
</i18n>

<script setup>
import UiButton from '@/components/ui/button/index.vue'
import UiAvatar from '@/components/ui/avatar/index.vue'
import UiTime from '@/components/ui/time/index.vue'
import UiUsername from '@/components/ui/username/index.vue'
import StarIcon from '@/components/clans/icons/star.svg'
import CrownIcon from '@/components/clans/icons/crown.svg'
import { useAccountStore } from '@/stores/account'
import { useClansMeStore } from '~/stores/clans/me'

const { $normalizePath, $dialog, $sdk } = useNuxtApp()
const $router = useRouter()
const { t, te } = useI18n()

const clansMeStore = useClansMeStore()
const accountStore = useAccountStore()

const pending = ref(false)
const roleIcons = {
  owner: CrownIcon,
  admin: StarIcon
}

const clan = computed(() => clansMeStore.data)
const roleIcon = roleIcons[clan.value.clanMember.role.id]
const roleName = clan.value.clanMember.role.name
const isRemove = computed(() => clansMeStore.permission('remove'))

const onLeave = () =>
  $dialog({
    wrapper: defineAsyncComponent(
      () => import('~/components/common/dialog/confirm-wrapper.vue')
    ),
    content: defineAsyncComponent(
      () => import('@/components/clans/members/modals/confirm-content.vue')
    ),
    wrapperProps: { acceptText: t('yes') },
    contentProps: { title: t('dialog-title') },
    onClose: yes => yes && onSubmit()
  })

const onSubmit = async () => {
  try {
    pending.value = true
    if (isRemove.value) {
      await $sdk.module('clans').then(({ removeClan }) => removeClan())
    } else {
      await $sdk.module('clans').then(({ leaveClan }) => leaveClan())
    }
    clansMeStore.flush()
    $router.push($normalizePath('/clans/rating'))
  } catch (e) {
  } finally {
    pending.value = false
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 2em;
  border-radius: 0.6em;
  background: #1a263a;

  @include down(md) {
    @include up(sm) {
      display: flex;
    }
  }
}

.info {
  padding-bottom: 1.6em;
  margin-bottom: 2em;
  border-bottom: 1px solid rgba(172, 176, 213, 0.2);
  display: flex;
  align-items: center;

  @include down(md) {
    @include up(sm) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;

      padding-right: 2.4em;
      margin-right: 2.4em;
      border-right: 1px solid rgba(172, 176, 213, 0.2);

      flex: 0 0 30em;
    }
  }
}

.avatar {
  --width: 8em;
  flex-shrink: 0;
}

.about {
  margin-left: 1.6em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.username {
  color: #00c7b1;
  font-family: var(--primary-font);
  font-size: 2.4em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.41;
}

.role {
  color: #acb0d5;
  display: flex;
  align-items: center;
  gap: 0.4em;

  .t {
    font-family: var(--primary-font);
    font-size: 1.6em;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.032em;
  }
}
.icon {
  width: 2.4em;
  height: auto;
  display: block;
}

.stats {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include down(md) {
    @include up(sm) {
      flex: 1;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7em;
}

.right {
  align-items: flex-end;
  text-align: right;
}

.key {
  color: #acb0d5;
  font-size: 1.6em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.value {
  font-family: var(--primary-font);
  font-size: 2.4em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.41;
  text-transform: uppercase;
}
</style>
