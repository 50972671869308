import { createSimpleStore } from '~/utils/pinia/light-simple-store'

export const useClansManageTokenStore = createSimpleStore(
  'clans-manage-token',
  {
    getRequests: async () => {
      const { $sdk } = useNuxtApp()
      const { getToken, createToken, removeToken } = await $sdk.module('clans')

      return {
        get: getToken,
        create: createToken,
        remove: removeToken
      }
    }
  }
)
